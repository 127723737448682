import React, { useState, useEffect } from "react";
import Layout from '../components/Layout';
import Item from "../interfaces/item";
import { useQuery, useLazyQuery } from "@apollo/client";
import { getRonToUSD, displayPrice, displayPriceLabel, getItem, priceCalc } from "../services/helperServices"
import { RON_TO_USD_QUERY, FILTER_ITEMS_QUERY, GET_ITEM_DETAILS_QUERY } from "../services/apiService"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Farming: React.FC = () => {
    const [seedData, setSeedData] = useState<Item[][]>();
    const [slimeData, setSlimeData] = useState<Item[]>();
    const [plantingEssenceData, setPlantingEssenceData] = useState<Item[]>();
    const [boxData, setBoxData] = useState<Item[]>();
    const [ronToUSDValue, setRonToUSDValue] = useState(0);

    const farmingPlantingEssenceIds = [1102352, 1102608, 1102864, 268539664, 268539920, 268540176]

    // carrot, trifolium, bamboo
    // cactus, cotton, pigmy sundew
    const plantingSeedsIds = [
        [1086480, 1086224, 1085968],
        [1087248, 1086992, 1086736],
        [1087504, 1087760, 1088016]
    ]

    const plantsIds = [
        [1067024, 1066768, 1066512],
        [1067792, 1067536, 1067280],
        [1068048, 1068304, 1088016]
    ]

    const plantingEssenceIds = [1102352, 1102608, 1102864]
    const slimeIds = [268558864, 268559120, 268559376, 268559632, 268559888, 268560144, 268560400]
    const packagingBoxIds = [
        268638224,
        268638480,
        268638736,
        268638992,
        268639248,
        268639504,
        268639760,
        268640016,
        268640272
    ]

    const loadItemsFromArray = async (idArray: number[]) => {
        var itemArray: Item[] = []
        if (idArray) {
            let asyncMap = idArray.map(async (id) => {
                itemArray.push(await getItem(id.toString(), getItemDetails));
            });

            const results = await Promise.all(asyncMap);
        }

        return itemArray;
    }

    const getSlimeTotalSeedCost = (level: number) => {
        if (!seedData) return 0;

        return priceCalc(seedData
            .filter((e, i) => i <= level - 1)
            .map(e => e.reduce((accumulator, currentValue) => accumulator + (currentValue.minPrice * 4), 0))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0));
    }

    useEffect(() => {
        const fetchData = async () => {
            let seeds: Item[][] = [];
            for (let i = 0; i < plantingSeedsIds.length; i++) {
                seeds.push(await loadItemsFromArray(plantingSeedsIds[i]))
            }

            setSeedData(seeds);

            let plantingEssences = await loadItemsFromArray(plantingEssenceIds)
            let slimes = await loadItemsFromArray(slimeIds)
            let boxes = await loadItemsFromArray(packagingBoxIds)

            setSlimeData(slimes.sort((a, b) => a.name > b.name ? 1 : -1));
            setPlantingEssenceData(plantingEssences.sort((a, b) => a.name > b.name ? 1 : -1));
            setBoxData(boxes.sort((a, b) => a.name > b.name ? 1 : -1));
        }

        fetchData();

        return () => {
        }
    }, []);

    useQuery(RON_TO_USD_QUERY, {
        fetchPolicy: 'cache-first', // Change this to control caching behavior
        onCompleted: (response) => {
            if (ronToUSDValue == 0) {
                setRonToUSDValue(response.exchangeRate.ron.usd);
            }
        }
    });

    const [getItemDetails, { loading, error, data }] = useLazyQuery(GET_ITEM_DETAILS_QUERY);

    if (!seedData || !boxData || !slimeData || !plantingEssenceData) return <div>loading</div>

    const createFarmingSlimeDataRow = (matsData: Item[], slimeData: Item[], level: number, isFirst: boolean) => {
        return (
            // <TableCell>item</TableCell>
            // <TableCell>level</TableCell>
            // <TableCell>sell price minus fees</TableCell>
            // <TableCell>profit buying seeds</TableCell>
            // <TableCell>profit buying everything</TableCell>
            // <TableCell>profit/energy buying seeds</TableCell>
            // <TableCell>profit/energy buying everything</TableCell>
            <TableRow>
                <TableCell style={{ verticalAlign: "top" }}>Slime</TableCell>
                <TableCell style={{ verticalAlign: "top" }}>{level}</TableCell>
                {/* sell price minus fees */}
                <TableCell style={{ verticalAlign: "top" }}>
                    {displayPriceLabel(priceCalc(slimeData[level - 1].minPrice * 0.9))}
                </TableCell>
                {/* profit buying seeds */}
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {displayPriceLabel(priceCalc(slimeData[level - 1].minPrice * 0.9) - getSlimeTotalSeedCost(level))}
                    </div>
                    <hr></hr>
                    <div>
                        seed cost: {displayPriceLabel(getSlimeTotalSeedCost(level))}
                    </div>
                    {/* Price of seeds */}
                    <div>
                        {
                            seedData[level - 1]
                                .map(e => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: "24px" }} src={e.cdnImage}></img> {e.name} - {priceCalc(e.minPrice)} x 4
                                    </div>
                                ))
                        }
                    </div>
                </TableCell>
                {/* profit buying previous slime */}
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {displayPriceLabel(
                            (priceCalc(slimeData[level - 1].minPrice * 0.9)
                                - (getSeedCostPerLevel(level, 4))
                                - (slimeData.filter((e, i) => i == level - 2).map(e => priceCalc(e.minPrice)).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                            ))
                        }
                    </div>
                    <hr></hr>
                    <div>
                        seed cost: {displayPriceLabel(priceCalc(matsData.map(e => e.minPrice * 4).reduce((accumulator, currentValue) => accumulator + currentValue, 0)))}
                    </div>
                    <div>
                        {/* Price of lower slimes */}
                        {slimeData.filter((e, i) => i == level - 2).map(e => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img style={{ width: "24px" }} src={e.cdnImage}></img> {e.name} - {priceCalc(e.minPrice)}
                            </div>
                        ))}
                    </div>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                    {/* profit/energy buying all seeds */}
                    <div>
                        {displayPriceLabel(
                            priceCalc(
                                (slimeData[level - 1].minPrice * 0.9)
                                - (matsData.map(e => e.minPrice * 4).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                            ) / (12 * level))
                        }
                    </div>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                    {/* profit/energy buying previous slimes */}
                    <div>
                        {displayPriceLabel(
                            priceCalc(
                                (slimeData[level - 1].minPrice * 0.9)
                                - (matsData.map(e => e.minPrice * 4).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                                - (slimeData.filter((e, i) => i == level - 2).map(e => e.minPrice).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                            ) / 12)
                        }
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const getSeedCostPerLevel = (level: number, count: number) => {
        return seedData[level - 1].map(e => priceCalc(e.minPrice) * count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }

    const getEssenceSeedCostUntilLevel = (level: number, count: number) => {
        var sum = 0;

        var essenceCount = 1;
        for (let i = level; i > 0; i--) {
            sum += getSeedCostPerLevel(i, count * essenceCount);
            essenceCount *= 2;
        }

        return sum;
    }

    const getEssenceCostBuyingPrevious = (level: number) => {
        return (
            (priceCalc(plantingEssenceData[level - 1].minPrice) * 0.9)
            // current level seed
            - getSeedCostPerLevel(level, 5)
            // previous essence
            - (plantingEssenceData
                .filter((e, i) => i == level - 2)
                .reduce((accumulator, essence) => accumulator + priceCalc(essence.minPrice), 0) * 2)
            // box
            - priceCalc(boxData[level - 1].minPrice)
        );
    }

    const getEssenceCostBuyingSeeds = (level: number) => {
        return (
            (priceCalc(plantingEssenceData[level - 1].minPrice) * 0.9)
            // current level seed
            - getEssenceSeedCostUntilLevel(level, 5)
            // box
            - priceCalc(boxData[level - 1].minPrice)
        );
    }

    const createFarmingEssenceDataRow = (level: number, isFirst: boolean) => {
        return (
            // <TableCell>item</TableCell>
            // <TableCell>level</TableCell>
            // <TableCell>sell price minus fees</TableCell>
            // <TableCell>profit buying seeds</TableCell>
            // <TableCell>profit buying everything</TableCell>
            // <TableCell>profit/energy buying seeds</TableCell>
            // <TableCell>profit/energy buying everything</TableCell>
            <TableRow>
                {/* item name */}
                <TableCell style={{ verticalAlign: "top" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img style={{ width: "24px" }} src={plantingEssenceData[level - 1].cdnImage}></img> Planting Essence {level}
                    </div>
                </TableCell>
                {/* sell price minus fees */}
                <TableCell style={{ verticalAlign: "top" }}>
                    {displayPriceLabel(priceCalc(plantingEssenceData[level - 1].minPrice * 0.9))}
                </TableCell>
                {/* profit buying all seeds */}
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {
                            displayPriceLabel(getEssenceCostBuyingSeeds(level))
                        }
                    </div>
                    <hr></hr>
                    <div style={{ fontSize: "8pt" }}>
                        seed cost: {displayPriceLabel(getSeedCostPerLevel(level, 5))}
                    </div>
                    {
                        (level == 1 ? <></> :
                            <div style={{ fontSize: "8pt" }}>
                                lower seed cost: {displayPriceLabel(getEssenceSeedCostUntilLevel(level, 5) - getSeedCostPerLevel(level, 5))}
                            </div>
                        )
                    }
                    {/* seed details */}
                    <div style={{ fontSize: "8pt" }}>
                        {
                            seedData[level - 1]
                                .map(e => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: "24px" }} src={e.cdnImage}></img> {e.name} - {priceCalc(e.minPrice)} x 5
                                    </div>
                                ))
                        }
                    </div>
                    {/* box details */}
                    <div style={{ fontSize: "8pt" }}>
                        {
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img style={{ width: "24px" }} src={boxData[level - 1].cdnImage}></img> {boxData[level - 1].name} - {priceCalc(boxData[level - 1].minPrice)}
                            </div>
                        }
                    </div>
                </TableCell>
                {/* profit buying everything*/}
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {displayPriceLabel(getEssenceCostBuyingPrevious(level))}
                    </div>
                    <hr></hr>
                    <div style={{ fontSize: "8pt" }}>
                        seed cost: {displayPriceLabel(getSeedCostPerLevel(level, 5))}
                    </div>
                    {/* Price of lower essences */}
                    <div style={{ fontSize: "8pt" }}>
                        {
                            plantingEssenceData
                                .filter((e, i) => i < level - 1)
                                .map(e => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: "24px" }} src={e.cdnImage}></img>
                                        {e.name} - {priceCalc(e.minPrice)} x 2
                                    </div>
                                ))
                        }
                    </div>
                    {/* box details */}
                    <div style={{ fontSize: "8pt" }}>
                        {
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img style={{ width: "24px" }} src={boxData[level - 1].cdnImage}></img> {boxData[level - 1].name} - {priceCalc(boxData[level - 1].minPrice)}
                            </div>
                        }
                    </div>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {displayPriceLabel(getEssenceCostBuyingSeeds(level) / (level * 15))}
                    </div>
                    <div>
                        energy spent: {(level * 15)}</div>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                    <div>
                        {displayPriceLabel(getEssenceCostBuyingPrevious(level) / 15)}
                    </div>
                    <div>
                        energy spent: {(15)}
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Layout>
            <div data-name="inner-content" style={{ display: "flex", justifyContent: "center", marginBottom: "10px", flexFlow: "wrap" }}>
                <h1>Farming (Planting) - Slimes</h1>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>item</TableCell>
                                <TableCell>level</TableCell>
                                <TableCell>sell price minus fees</TableCell>
                                <TableCell>profit buying seeds</TableCell>
                                <TableCell>profit buying everything</TableCell>
                                <TableCell>profit/energy buying seeds</TableCell>
                                <TableCell>profit/energy buying everything</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {seedData.map((seeds, i) => createFarmingSlimeDataRow(seeds, slimeData, (i + 1), i == 0))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h1>Farming (Planting) - Essences</h1>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>item</TableCell>
                                <TableCell>sell price minus fees</TableCell>
                                <TableCell>profit buying seeds</TableCell>
                                <TableCell>profit buying everything</TableCell>
                                <TableCell>profit/energy buying seeds</TableCell>
                                <TableCell>profit/energy buying everything</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                seedData.map((seeds, i) => createFarmingEssenceDataRow((i + 1), i == 0))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout >
    );
};

export default Farming;

